@font-face {
    font-family: 'AkkuratRegular';
    src: url('./fonts/AkkuratRegular.woff');
}

@font-face {
    font-family: 'PTSerif';
    src: url('./fonts/AkkuratRegular.woff');
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    font-size: 100%;
} /*16px*/

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
}

body {
    font-family: AkkuratRegular, sans-serif;
    line-height: 1.6;
}

#root {
    min-height: 100%;
    height: 100%;
}

h1, h2, h3 {
    font-family: "PT Serif", Times New Roman, serif;
}

button {
    font-family: AkkuratRegular, sans-serif;
}

.link {
    color: inherit;
    text-decoration: none;
}